<template>
    <div>
        <b-card class="question-details-card p-1">
            <div
                class="question-details-card__header d-flex justify-content-between mb-2"
            >
                <div class="question-details-card__title-container d-flex">
                    <unicon name="file-question" width="20" height="20" />
                    <h4 class="question-details-card__title ml-1 mb-0">
                        سؤال جديد
                    </h4>
                </div>
            </div>

            <ValidationObserver ref="questionDetailsObserver">
                <b-form class="mt-1">
                    <b-row>
                        <b-col md="12">
                            <ek-input-text
                                :rules="[
                                    {
                                        type: 'required',
                                        message: 'السؤال مطلوب',
                                    },
                                ]"
                                placeholder="ادخل السؤال"
                                label="السؤال"
                                v-model="questionDto.question"
                                name="السؤال"
                            >
                            </ek-input-text>
                        </b-col>
                        <b-col md="12">
                            <div
                                class="question-details-card__answer-container"
                            >
                                <ek-input-textarea
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'الجواب مطلوب',
                                        },
                                    ]"
                                    placeholder="ادخل الجواب"
                                    label="الجواب"
                                    v-model="questionDto.answer"
                                    name="الجواب"
                                >
                                </ek-input-textarea>
                            </div>
                            <b-button
                                variant="flat-secondary"
                                class="question-details-card__trash-button btn-icon btn-sm rounded-circle"
                                @click="deleteAnswerText"
                            >
                                <unicon
                                    width="16"
                                    class="svg-danger"
                                    name="trash"
                                ></unicon>
                            </b-button> 
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>

            <div
                class="question-details-card__footer d-flex justify-content-end mt-2"
            >
                <b-button
                    @click="updateCurrentQuestion"
                    variant="primary"
                    class="text-white"
                    >تعديل</b-button
                >
                <b-button
                    @click="navigateToSettings"
                    variant="outline-primary ml-2"
                    >تراجع</b-button
                >
            </div>
        </b-card>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    computed: {
        ...mapState({
            questionDto: (state) => state.settings.questionDto,
        }),
    },
    props: {
        id: String,
    },
    created() {
        this.getQuestionsDetails(this.id);
    },
    methods: {
        ...mapActions(["updateQuestion", "getQuestionsDetails"]),
        ...mapMutations(["Remove_Setting_Answer_Text"]),
        navigateToSettings() {
            this.$router.push("/admin/settings");
        },
        deleteAnswerText() {
            this.Remove_Setting_Answer_Text()
        },
        updateCurrentQuestion() {
            this.$refs.questionDetailsObserver.validate().then((success) => {
                if (success) {
                    this.updateQuestion(this.questionDto);
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>

.question-details-card {
    &__answer-container {
        position: relative;
    }

    &::v-deep {
        textarea {
            height: 200px;
        }
    }

    &__trash-button::v-deep {
        position: absolute;
        top: 0.3rem;
        right: 1rem;
        padding: 2px 6px !important;

    }
}
</style>