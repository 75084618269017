var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"question-details-card p-1"},[_c('div',{staticClass:"question-details-card__header d-flex justify-content-between mb-2"},[_c('div',{staticClass:"question-details-card__title-container d-flex"},[_c('unicon',{attrs:{"name":"file-question","width":"20","height":"20"}}),_c('h4',{staticClass:"question-details-card__title ml-1 mb-0"},[_vm._v(" سؤال جديد ")])],1)]),_c('ValidationObserver',{ref:"questionDetailsObserver"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('ek-input-text',{attrs:{"rules":[
                                {
                                    type: 'required',
                                    message: 'السؤال مطلوب',
                                } ],"placeholder":"ادخل السؤال","label":"السؤال","name":"السؤال"},model:{value:(_vm.questionDto.question),callback:function ($$v) {_vm.$set(_vm.questionDto, "question", $$v)},expression:"questionDto.question"}})],1),_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"question-details-card__answer-container"},[_c('ek-input-textarea',{attrs:{"rules":[
                                    {
                                        type: 'required',
                                        message: 'الجواب مطلوب',
                                    } ],"placeholder":"ادخل الجواب","label":"الجواب","name":"الجواب"},model:{value:(_vm.questionDto.answer),callback:function ($$v) {_vm.$set(_vm.questionDto, "answer", $$v)},expression:"questionDto.answer"}})],1),_c('b-button',{staticClass:"question-details-card__trash-button btn-icon btn-sm rounded-circle",attrs:{"variant":"flat-secondary"},on:{"click":_vm.deleteAnswerText}},[_c('unicon',{staticClass:"svg-danger",attrs:{"width":"16","name":"trash"}})],1)],1)],1)],1)],1),_c('div',{staticClass:"question-details-card__footer d-flex justify-content-end mt-2"},[_c('b-button',{staticClass:"text-white",attrs:{"variant":"primary"},on:{"click":_vm.updateCurrentQuestion}},[_vm._v("تعديل")]),_c('b-button',{attrs:{"variant":"outline-primary ml-2"},on:{"click":_vm.navigateToSettings}},[_vm._v("تراجع")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }